import {
  ADD_SUBJECT_SUCCESS,
  GET_SUBJECT_SUCCESS,
  SET_CURRENT_SUBJECT_ID,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECT_ORDER_SUCCESS,
  GET_SUBJECT_SUMMARY_SUCCESS,
  EDIT_SUBJECT_SUCCESS,
  GET_SUBJECTS_ADMIN_SUCCESS,
  ADD_SUBJECT_ERROR
} from '../common/constants';
import subjectApi from '../api/subject-api';
import ApiHelper from '../api/apiCallsHelper';
import config from '../common/config';

export function addSubjectSuccess(subject) {
  return {
    type: ADD_SUBJECT_SUCCESS,
    subject
  };
}

export function editSubjectSuccess(subject) {
  return {
    type: EDIT_SUBJECT_SUCCESS,
    subject
  };
}

export function addSubject(user) {
  return function(dispatch) {
    return subjectApi
      .addSubject(user)
      .then(r => {
        if (r.subject_id) {
          dispatch(addSubjectSuccess(r));         
        } else {
          dispatch(addSubjectError(r));
        }
      })
      .catch(err => {
        dispatch(addSubjectError(err));
      });
  };
}

export function editSubject(user) {
  return function() {
    return subjectApi
      .editSubject(user)
      .then(r => {
        if (r.status === 200) {
          window.location.href = '/individuals';
        } else {
          console.log(r);
        }
      })
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  };
}

export function deleteSubject(subjectId) {
  return function(dispatch) {
    const req = {
      subject_id: subjectId
    };
    return ApiHelper.postWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/delete`,
      req
    )
      .then(r => {
        console.log(r);
        window.location.href = '/individuals';
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getSubjectOrders(subjectId) {
  return function(dispatch) {
    return ApiHelper.getWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/subject-orders/${parseInt(
        subjectId
      )}`
    )
      .then(r => dispatch(getSubjectOrdersSuccess(r)))
      .catch(err => {
        throw err;
      });
  };
}
export function getSubjects(req) {
  return function(dispatch) {
    return ApiHelper.postWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/all`,
      req
    )
      .then(r => dispatch(getSubjectsSuccess(r.data)))
      .catch(err => {
        throw err;
      });
  };
}

export function getSubjectSummary(subjectId) {
  return function(dispatch) {
    return ApiHelper.getWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/summary/${subjectId}`
    )
      .then(r => dispatch(getSubjectSummarySuccess(r)))
      .catch(err => {
        throw err;
      });
  };
}

export function getSubject(subjectId) {
  return function(dispatch) {
    return ApiHelper.getWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/get/${parseInt(
        subjectId
      )}`
    )
      .then(r => dispatch(getSubjectSuccess(r)))
      .catch(err => {
        throw err;
      });
  };
}

export function getSubjectsForAdmin(req) {
  return function(dispatch) {
    return ApiHelper.postWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/individuals/from-all-clients`,
      req
    )
      .then(r => dispatch(getSubjectsForAdminSuccess(r.data)))
      .catch(err => {
        if(err.status && err.status === 403){
          console.log(err)
        }else{
          throw err;
        }
      });
  };
}

export function getSubjectSuccess(subject) {
  return {
    type: GET_SUBJECT_SUCCESS,
    subject
  };
}

function setCurrentSubject(subject_id) {
  return {
    type: SET_CURRENT_SUBJECT_ID,
    subject_id
  };
}

export function setCurrentSubjectId(subject_id) {
  return function(dispatch) {
    dispatch(setCurrentSubject(subject_id));
  };
}

function getSubjectsSuccess(subjects) {
  return {
    type: GET_SUBJECTS_SUCCESS,
    subjects
  };
}

function getSubjectOrdersSuccess(orders) {
  return {
    type: GET_SUBJECT_ORDER_SUCCESS,
    orders
  };
}

function getSubjectSummarySuccess(subjectSummary) {
  return {
    type: GET_SUBJECT_SUMMARY_SUCCESS,
    subjectSummary
  };
}
function getSubjectsForAdminSuccess(subjects) {
  return {
    type: GET_SUBJECTS_ADMIN_SUCCESS,
    subjects
  };
}

function addSubjectError(error) {
  return {
    type: ADD_SUBJECT_ERROR,
    addSubjectError: error
  };
}
