import {
  GET_ORDER_DETAILS_SUCCESS,
  GET_ONE_TIME_CHECK_LIST,
  RUN_ONE_TIME_CHECK,
  SET_CURRENT_ORDER_ID,
  GET_ORDERS_BY_RESULT_SUCCESS,
  COUNT_ORDERS_BY_RESULT_SUCCESS,
  GET_USER_PROCESSING_ORDERS_SUCCESS,
} from '../common/constants';
export default (state = [], action) => {
  switch (action.type) {
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        details: action.details
      };
    case GET_ONE_TIME_CHECK_LIST:
      return {
        ...state,
        oneTimeCheckList: action.oneTimeCheckList
      };
    case RUN_ONE_TIME_CHECK:
      return { ...state, oneTimeCheckResult: action.oneTimeCheckResult };
    case SET_CURRENT_ORDER_ID:
      return { currentOrderId: action.order_id };
    case GET_ORDERS_BY_RESULT_SUCCESS:
      return { ...state, ordersByResult: action.ordersByResult };
    case COUNT_ORDERS_BY_RESULT_SUCCESS:
      return { ...state, ordersCount: action.ordersCount };
    case GET_USER_PROCESSING_ORDERS_SUCCESS:
      return { ...state, processingOrders: action.processingOrders };
    default:
      return state;
  }
};
