import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../common/textFieldGroup';
import SelectFieldGroup from '../common/selectFieldGroup';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { addSubject } from '../../actions/subjectActions';
import validateAddSubject from '../../validations/addSubjectValidation';
import { withRouter } from '../../common/utils';
import States from '../../static/states.json';
import SelectFieldGroupFromArray from '../common/selectFieldGroupFromArray';
import Counties from '../../static/counties.json';
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';

class AddSubjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      dob: '',
      ssn: '',
      gender: '',
      city: '',
      state: '',
      county: '',
      errors: {},
      isValid: {},
      counties: [],
      address1: '',
      address2: '',
      email: '',
      phone: '',
      zip: '',
      country: 'US',
      showLoader: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.addSubjectError) {
      ToastService.show(
        'Individual has not been created , please contact support',
        'error'
      );
    }
    if (nextProps.addSubjectSuccess) {
      ToastService.show(nextProps.addSubjectSuccess.message, 'success');
      setInterval(function() {
        window.location.href = '/individuals';
      }, 2000);
    }
  }

  onChange(e) {
    if (e.target.name === 'state') {
      this.setState({
        counties: Counties[e.target.value.toString()],
        state: e.target.value
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ showLoader: true });
    if (this.isValid()) {
      this.props.addSubject({ ...this.state, gender: this.state.gender[0] });
    }
    this.setState({ showLoader: false });
  }

  isValid() {
    let { errors, isValid } = validateAddSubject(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  showToast() {
    ToastService.show('Successfully added new individual!', 'success');
  }

  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <Card className='card-box mt-5'>
              <Form onSubmit={this.onSubmit}>
                <Card.Body>
                  <Card.Title>
                    <h2>
                      <i className='material-icons md-36'>person_add</i>Add
                      Individual
                    </h2>
                  </Card.Title>
                  <h5 className='mt-5 mb-3'>Individual's Personal Info</h5>
                  <Card.Text />
                  <TextFieldGroup
                    error={this.state.errors.firstName}
                    label='First name'
                    onChange={this.onChange}
                    value={this.state.firstName}
                    field='firstName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.lastName}
                    label='Last name'
                    onChange={this.onChange}
                    value={this.state.lastName}
                    field='lastName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.middleName}
                    label='Middle name'
                    onChange={this.onChange}
                    value={this.state.middleName}
                    field='middleName'
                  />
                  <TextFieldGroup
                    error={this.state.errors.dob}
                    label='Date of Birth (Format: YYYY-MM-DD)'
                    onChange={this.onChange}
                    value={this.state.dob}
                    field='dob'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.ssn}
                    label='SSN (Format: AAA-BB-CCCC)'
                    onChange={this.onChange}
                    value={this.state.ssn}
                    field='ssn'
                  />
                  <TextFieldGroup
                    error={this.state.errors.address1}
                    label='Address 1'
                    onChange={this.onChange}
                    value={this.state.address1}
                    field='address1'
                  />
                  <TextFieldGroup
                    error={this.state.errors.address2}
                    label='Address 2'
                    onChange={this.onChange}
                    value={this.state.address2}
                    field='address2'
                  />
                  <SelectFieldGroup
                    error={this.state.errors.gender}
                    label='Gender'
                    onChange={this.onChange}
                    value={this.state.gender}
                    required={true}
                    name='gender'
                  />
                  <TextFieldGroup
                    error={this.state.errors.city}
                    label='City'
                    onChange={this.onChange}
                    value={this.state.city}
                    field='city'
                  />
                  <SelectFieldGroupFromArray
                    error={this.state.errors.state}
                    label='State'
                    onChange={this.onChange}
                    value={this.state.state}
                    field='state'
                    options={States.Values}
                    name='state'
                    placeholder='- - Select state - -'
                    required={true}
                  />
                  <SelectFieldGroupFromArray
                    error={this.state.errors.county}
                    label='County'
                    onChange={this.onChange}
                    value={this.state.county}
                    options={this.state.counties}
                    field='county'
                    name='county'
                    placeholder='- - Select county - -'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.zip}
                    label='Zip'
                    onChange={this.onChange}
                    value={this.state.zip}
                    field='zip'
                  />
                  <TextFieldGroup
                    error={this.state.errors.phone}
                    label='Phone'
                    onChange={this.onChange}
                    value={this.state.phone}
                    field='phone'
                  />
                  <TextFieldGroup
                    error={this.state.errors.email}
                    label='Email'
                    onChange={this.onChange}
                    value={this.state.email}
                    field='email'
                  />
                  <Button variant='primary' className='mt-3 mb-2' type='submit'>
                    <i className='material-icons'>person_add</i>
                    Add Individual
                  </Button>
                  {loader}
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  addSubject
};

const mapStateToProps = state => {
  return {
    addSubjectError: state.subjectReducer.addSubjectError,
    addSubjectSuccess: state.subjectReducer.subjectCreate
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(AddSubjectPage)
);
