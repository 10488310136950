import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import {
  getSubject,
  getSubjectSummary,
  getSubjectOrders,
  deleteSubject
} from '../../actions/subjectActions';
import OneTimeCheckPopUp from '../../components/Modals/OneTimeCheck';
import { Link, useLocation } from 'react-router-dom';
import apiCallsHelper from '../../api/apiCallsHelper';
import config from '../../common/config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';
import UserApi from './../../api/user-api.js';
import { getSubjectAuths } from '../../actions/authorizationActions';

const ViewSubjectPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('info');
  const [subjectId, setSubjectId] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [user, setUser] = useState(null);
  const [ordersCount, setOrdersCount] = useState(0);

  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subjectReducer.subject);
  const subjectSummary = useSelector((state) => state.subjectReducer.subjectSummary);
  const subjectOrders = useSelector((state) => state.subjectReducer.subjectOrders);
  const subjectAuth = useSelector((state) => state.authorizationReducer.subjectAuth);
  
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const { subjectId } = location.state;
      setSubjectId(subjectId);
      dispatch(getSubject(subjectId));
      dispatch(getSubjectSummary(subjectId));
      dispatch(getSubjectOrders(subjectId));
      dispatch(getSubjectAuths(subjectId));

      try {
        const fetchedUser = await UserApi.getUserWithJwt();
        setUser(fetchedUser);

        const orders = await apiCallsHelper.postWithJWT(
          `${config.trueFingerPrintsAPI.baseURL}/orders/orders-limit`
        );
        setOrdersCount(orders.data.count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch, location.state]);

  useEffect(() => {
    if (subject) {
      setAdditionalInfo(subject.additionalInfo || '');
    }
  }, [subject]);

  const handleChange = (e) => {
    setAdditionalInfo(e.target.value);
  };

  const handleDeleteSubject = () => {
    dispatch(deleteSubject(subjectId));
  };

  const saveNote = async () => {
    setShowLoader(true);
    try {
      const req = { subject_id: subjectId, additionalInfo };
      await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/additional-info`,
        req
      );
      setShowLoader(false);
      ToastService.show('Successfully added additional info!', 'success');
    } catch (error) {
      console.log('error', error.response);
      setShowLoader(false);
      ToastService.show(
        `${error.response.statusText},please contact customer support.`,
        'error'
      );
    }
  };

  const submitDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h1>Remove Individual?</h1>
          <p>Are you sure you want to remove this individual?</p>
          <Button variant='outline-secondary mt-3' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='danger ml-4 mt-3'
            onClick={() => {
              handleDeleteSubject();
              onClose();
            }}
          >
            Yes, Remove it!
          </Button>
        </div>
      )
    });
  };

  const loader = showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            {subjectOrders && subjectOrders.length > 0 ? (
              <p className='subject-cannot-edit-info'>
                {' '}
                This individual can not be edited while a search has been
                initiated. If you would like to make a new search with new
                information for the same individual please add the same
                individual again in the Individuals page.
              </p>
            ) : (
                <ButtonToolbar className='d-flex justify-content-center mt-5 mb-5'>
                  <Link
                    variant='outline-primary'
                    to={'/edit-subject'}
                    state={{ currentSubjectId: location.state.subjectId }}
                  >
                    <i className='material-icons'>edit</i>
                  Edit Individual's Info
                </Link>
                </ButtonToolbar>
              )}
            <hr />
            <h1 className='text-center mt-5'>
              {subject ? subject.name_first : null}{' '}
              {subject && subject.name_middle
                ? subject.name_middle
                : null}{' '}
              {subject ? subject.name_last : null}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3 mb-3' lg={12}>
            <ButtonToolbar className='d-flex justify-content-center mb-5'>
              {subject && subject['Orders.id'] ? (
                <Button
                  variant='primary mx-3 mt-3'
                  className='disabled'
                >
                  <i className='material-icons'>fingerprint</i>PreHire
                  Processing
                </Button>
              ) : (
                  <Button
                    variant='primary mx-3 mt-3'
                    onClick={() => setShowModal(true)}
                  >
                    <i className='material-icons'>fingerprint</i>Run PreHire
                  Screening
                  </Button>
                )}
            </ButtonToolbar>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='mb-4 mt-3'>Individual's Information</h5>
                <ListGroup.Item>
                  First Name:
                  <span className='pl-2'>
                    {subject ? subject.name_first : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Middle Name:
                  <span className='pl-2'>
                    {subject && subject.name_middle
                      ? subject.name_middle
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Last Name:
                  <span className='pl-2'>
                    {subject ? subject.name_last : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  City:
                  <span className='pl-2'>
                    {subject ? subject.city : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  State:
                  <span className='pl-2'>
                    {subject
                      ? subject.state.split('-').pop()
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  County:
                  <span className='pl-2'>
                    {subject ? subject.county : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Zip:
                  <span className='pl-2'>
                    {subject ? subject.zip : null}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='mb-4 mt-3'>Additional Information</h5>
                <ListGroup.Item>
                  DOB:
                  <span className='pl-2'>
                    {subject && subject.json_result
                      ? JSON.parse(subject.json_result).dob
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  SSN:
                  <span className='pl-2'>
                    {subject ? subject.ssn : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Address 1:
                  <span className='pl-2'>
                    {subject ? subject.address1 : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Address 2:
                  <span className='pl-2'>
                    {subject ? subject.address2 : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Gender:
                  <span className='pl-2'>
                    {subject
                      ? JSON.parse(subject.json_result).gender
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  phone:
                  <span className='pl-2'>
                    {subject ? subject.phone : null}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>

          <Col lg={12}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
              <h5 className='mb-4 mt-3'>FCRA Authorization</h5>
              <ListGroup.Item>
                Authorization Method:
              <span className='pl-2'>
              {subjectAuth && subjectAuth.length ?
                  subjectAuth.map(s => {
                    return (
                      <span key={s.id}>
                        {"Automatic: " + s.firstName + " " + s.lastName + " [AUTHORIZED]" +"; "}
                      </span>
                    )
                  }) 
                : "Manual"}
              </span>
              </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>

          <Col lg={12}>
            <article className='results-component subject-summary mb-5'>
              <h5 className='mt-3'>Background Checks Summary</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Total Orders</th>
                    <th>Cases</th>
                    <th>Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {subjectSummary
                        ? subjectSummary.ordersCount
                        : 0}
                    </td>
                    <td>
                      {subjectSummary
                        ? subjectSummary.casesCount
                        : 0}
                    </td>
                    <td>
                      {subjectSummary
                        ? subjectSummary.chargesCount
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </article>
          </Col>

          <Col lg={12}>
            <h5 className='box title-box-quaternary mt-5'>
              List of All Background Checks
            </h5>
          </Col>

          <Col lg={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subjectOrders
                  ? subjectOrders.map(i => {
                    return (
                      <tr key={i.id}>
                        <td>{i.createdAt}</td>
                        <td>{i.order_id}</td>
                        <td>
                          <Link
                              to={'/history'}
                              state={{ currentOrderId: i.id, currentSubjectId: i.subject_id }}
                          >
                            <Button variant='primary'>
                              <i className='material-icons'>pageview</i>
                                View Results
                              </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </Table>
          </Col>

          <Col lg={12}>
            <h5 className='box title-box-quaternary mt-5 mb-3'>
              Additional Information
            </h5>
          </Col>

          <Col lg={12}>
            <Form.Group controlId='additionalInfoArea'>
              <Form.Label className='mb-3'>
                You can write additional information about this Individual here.
                Your Account Manager may enter additional information about this
                Individual in the field below.
              </Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                onChange={handleChange}
                name='additionalInfo'
                value={additionalInfo}
              />
            </Form.Group>
            {loader}
            <ButtonToolbar className='d-flex justify-content-end mt-3'>
              <Button variant='primary' onClick={saveNote}>
                <i className='material-icons'>save</i>
                Save
              </Button>
            </ButtonToolbar>
          </Col>

          <Col className='mt-5' lg={12}>
            <hr />
            <ButtonToolbar className='d-flex justify-content-center mt-5'>
              <Button variant='outline-danger' onClick={submitDelete}>
                <i className='material-icons'>delete_forever</i>
                Remove This Individual
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        {showModal ? (
          <OneTimeCheckPopUp
            show={showModal}
            onHide={() => setShowModal(false)}
            subjectId={location.state.subjectId}
            county={subject ? subject.county : null}
            state={subject ? subject.state : null}
            user={user}
          />
        ) : null}
      </Container>
    );
  }


  export default ViewSubjectPage;
