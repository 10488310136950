import ApiCall from '../api/apiCallsHelper';
import {
  GET_ORDER_DETAILS_SUCCESS,
  GET_ONE_TIME_CHECK_LIST,
  RUN_ONE_TIME_CHECK,
  SET_CURRENT_ORDER_ID,
  GET_ORDERS_BY_RESULT_SUCCESS,
  COUNT_ORDERS_BY_RESULT_SUCCESS,
  GET_USER_PROCESSING_ORDERS_SUCCESS
} from '../common/constants';
import config from '../common/config';
import apiCallsHelper from '../api/apiCallsHelper';

export function getOrderDetailsSuccess(orderDetails) {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    details: orderDetails
  };
}

export function getOneTimeCheckListSuccess(oneTimeCheckList) {
  return {
    type: GET_ONE_TIME_CHECK_LIST,
    oneTimeCheckList: oneTimeCheckList
  };
}

export function runOneTimeCheckSuccess(oneTimeCheckResult) {
  return {
    type: RUN_ONE_TIME_CHECK,
    oneTimeCheckResult: oneTimeCheckResult
  };
}

export function getOrderDetails(orderId) {
  return function(dispatch) {
    const params = {
      orderId: orderId
    };
    return ApiCall.getWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/orders/details/${orderId}`,
      params
    )
      .then(r => {
        dispatch(getOrderDetailsSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getOneTimeCheckList(req) {
  return function(dispatch) {
    return ApiCall.postWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/orders/for-preview`,
      req
    )
      .then(r => {
        dispatch(getOneTimeCheckListSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function runOneTimeCheck(req) {
  return function(dispatch) {
    return ApiCall.postWithJWT(
      `${config.trueFingerPrintsAPI.baseURL}/orders/one-time-check`,
      req
    )
      .then(r => {
        dispatch(runOneTimeCheckSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function setCurrentOrderId(order_id) {
  return function(dispatch) {
    return dispatch(setOrderId(order_id));
  };
}

function setOrderId(order_id) {
  return {
    type: SET_CURRENT_ORDER_ID,
    order_id
  };
}

export function getOrdersByResult(resultTypeId, searchText, page) {
  return function(dispatch) {
    const req = {
      orderResultTypeId: resultTypeId,
      searchText,
      page
    };
    apiCallsHelper
      .postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/by-results`,
        req
      )
      .then(r => {
        dispatch(getOrdersByResultSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

function getOrdersByResultSuccess(orders) {
  return {
    type: GET_ORDERS_BY_RESULT_SUCCESS,
    ordersByResult: orders
  };
}

export function getOrdersCountByResult() {
  return function(dispatch) {
    apiCallsHelper
      .getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/count-orders-by-result`
      )
      .then(r => {
        dispatch(countOrdersByResultSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}

function countOrdersByResultSuccess(ordersCount) {
  return {
    type: COUNT_ORDERS_BY_RESULT_SUCCESS,
    ordersCount: ordersCount
  };
}

export function getUserProcessingOrders(page) {
  return function(dispatch) {
    apiCallsHelper
      .getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/processing-orders/${page}`
      )
      .then(r => dispatch(getUserProcessingOrdersSuccess(r)))
      .catch(err => {
        throw err;
      });
  };
}

function getUserProcessingOrdersSuccess(processingOrders) {
  return {
    type: GET_USER_PROCESSING_ORDERS_SUCCESS,
    processingOrders
  };
}
