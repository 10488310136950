import {
  ADD_SUBJECT_SUCCESS,
  GET_SUBJECT_SUCCESS,
  SET_CURRENT_SUBJECT_ID,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECT_ORDER_SUCCESS,
  GET_SUBJECT_SUMMARY_SUCCESS,
  EDIT_SUBJECT_SUCCESS,
  DELETE_SUBJECT_SUCCESS,
  GET_SUBJECTS_ADMIN_SUCCESS,
  ADD_SUBJECT_ERROR
} from '../common/constants';
export default (state = [], action) => {
  switch (action.type) {
    case ADD_SUBJECT_SUCCESS:
      return {
        subjectCreate: action.subject
      };
    case GET_SUBJECT_SUCCESS:
      return {
        ...state,
        subject: action.subject
      };
    case SET_CURRENT_SUBJECT_ID:
      return {
        currentSubjectId: action.subject_id
      };
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectsList: action.subjects
      };
    case GET_SUBJECT_SUMMARY_SUCCESS:
      return {
        ...state,
        subjectSummary: action.subjectSummary
      };
    case GET_SUBJECT_ORDER_SUCCESS: {
      return {
        ...state,
        subjectOrders: action.orders
      };
    }
    case EDIT_SUBJECT_SUCCESS: {
      return {
        ...state,
        subject: action.subject
      };
    }
    case DELETE_SUBJECT_SUCCESS: {
      return {
        ...state,
        subject: action.subject
      };
    }
    case GET_SUBJECTS_ADMIN_SUCCESS: {
      return {
        ...state,
        subjectsListAdmin: action.subjects
      };
    }
    case ADD_SUBJECT_ERROR: {
      return {
        ...state,
        addSubjectError: action.addSubjectError
      };
    }
    default:
      return state;
  }
};
