import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import SubjectsIcon from '../../images/SubjectsIcon.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { getSubjectsForAdmin } from '../../actions/subjectActions';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

class SubjectsListForAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      subjectId: '',
      searchText: ''
    };
    this.showModal = this.showModal.bind(this);
    this.loadSubjectsForAdmin = this.loadSubjectsForAdmin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  showModal(subjectId) {
    this.setState({ showModal: true, subjectId: subjectId });
  }
  componentDidMount() {
    this.loadSubjectsForAdmin(1);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  loadSubjectsForAdmin(page) {
    const req = {
      page: page,
      searchText: this.state.searchText
    };
    this.props.getSubjectsForAdmin(req);
  }
  handlePageClick = e => {
    this.loadSubjectsForAdmin(e.selected + 1);
  };
  render() {
    return (
      <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item>
                <Link to='/'>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Individuals</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className='table-section'>
          <Col lg={12}>
            <figure className='icon-group mt-5'>
              <img src={SubjectsIcon} alt='Individuals List Icon' />
              <h2 className='heading ml-3'>Individuals List</h2>
            </figure>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search for Individual'
                className='mr-sm-2'
                name='searchText'
                onChange={this.handleChange}
              />
              <Button variant='outline-dark mb-3 mt-3' onClick={()=> this.loadSubjectsForAdmin(1)}>Find</Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Profile Details</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>State</th>
                    <th>County</th>
                    <th>City</th>
                    <th>Address</th>
                    <th>Created by</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.subjectsListAdmin
                    ? this.props.subjectsListAdmin.rows.map(i => {
                        return (
                          <tr key={i.subject_id}>
                            <td>
                              <Link
                                to={'/view-subject'}
                                state={{ subjectId: i.subject_id }}
                              >
                                <Button variant='link'>
                                  <i className='material-icons'>visibility</i>
                                  View Profile
                                </Button>
                              </Link>
                            </td>
                            <td>{i.name_first}</td>
                            <td>{i.name_last}</td>
                            <td>{i.dob}</td>
                            <td>{i.state}</td>
                            <td>{i.county}</td>
                            <td>{i.city}</td>
                            <td>{i.address1}</td>
                            <td>{i.User ? i.User.email : null}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.subjectsListAdmin
                    ? Math.ceil(this.props.subjectsListAdmin.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getSubjectsForAdmin
};

const mapStateToProps = state => {
  return {
    subjectsListAdmin: state.subjectReducer.subjectsListAdmin
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SubjectsListForAdmin);
