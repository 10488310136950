import React, { Component } from 'react';
import { connect } from 'react-redux';
import './scss/style.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import OneTimeCheckList from './components/Order/OneTimeCheckList';
import { getOrdersCountByResult } from './actions/orderActions';
import { getUserWithJwt } from './actions/userActions';
import ApiHelper from './api/apiCallsHelper';
import config from './common/config';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      fullName: '',
    };
  }

  async componentDidMount() {
    this.props.getOrdersCountByResult();
    this.props.getUserWithJwt();
    try {
      let oneTimeOrders = await ApiHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/orders-limit`
      );
      this.setState({
        oneTimeOrders: oneTimeOrders.data.count

      });
    } catch (error) { }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user) {
      this.setState({
        id: newProps.user.Id,
        fullName: newProps.user.fullName,
        preHireLimit: newProps.user.preHireLimit,
      });
    }
  }
      
  render() {
    let notice = null;
    if(this.state.preHireLimit === 0) {
      notice = <Row>
                <Col>
                  <h5 className='py-5 mx-5 mt-4 notice'>
                    <i className='material-icons'>warning</i>
                    Account Restricted: This account does not have an active plan or FCRA compliance validation. 
                    Please send an email to: support@personnelgraph.net.
                  </h5>
                </Col>
              </Row>
    } else if ((this.state.preHireLimit - this.state.oneTimeOrders) >= 5) {
      notice = <Row>
                <Col>
                  <h5 className='py-5 mx-5 mt-4 notice-success'>
                    <i className='material-icons'>check_circle</i>
                    Account Plan: Active plan with {this.state.preHireLimit - this.state.oneTimeOrders} Pre-Hire limit and unlimited FCRA validations. 
                    Interested in renewing or upgrading your plan? Send an email to: support@personnelgraph.net.
                  </h5>
                </Col>
              </Row>
    } else if ((this.state.preHireLimit - this.state.oneTimeOrders) < 5) {
      notice = <Row>
                <Col>
                  <h5 className='py-5 mx-5 mt-4 notice-alert'>
                    <i className='material-icons'>warning</i>
                    Account Plan: Active plan with {this.state.preHireLimit - this.state.oneTimeOrders} Pre-Hire limit and unlimited FCRA validations. 
                    Interested in renewing or upgrading your plan? Send an email to: support@personnelgraph.net.
                  </h5>
                </Col>
              </Row>
    } 
    return (
      <Container fluid>
        <Row>
          <Col className='header-stats' lg={12}>
            <section className='results mt-5 mb-5'>
              <article className='clear'>
                <h1 className='clear-colorize'>
                  {this.props.ordersCount && this.props.ordersCount.clear
                    ? this.props.ordersCount.clear
                    : 0}
                </h1>
                <h4>Clear Results</h4>
                <Link to='/results/clear'>
                  <Button variant='link'>
                    <i className='material-icons'>search</i>View All
                  </Button>
                </Link>
              </article>
              <article className='alerts'>
                <h1 className='alert-colorize'>
                  {this.props.ordersCount && this.props.ordersCount.alert
                    ? this.props.ordersCount.alert
                    : 0}
                </h1>
                <h4>Results with Alerts</h4>
                <Link to='/results/alerts'>
                  <Button variant='link'>
                    <i className='material-icons'>search</i>View All
                  </Button>
                </Link>
              </article>
              <article className='awaiting'>
                <h1 className='awaiting-colorize'>
                  {this.props.ordersCount && this.props.ordersCount.pending
                    ? this.props.ordersCount.pending
                    : 0}
                </h1>
                <h4>Awaiting Info</h4>
                <Link to='/results/awaiting'>
                  <Button variant='link'>
                    <i className='material-icons'>search</i>View All
                  </Button>
                </Link>
              </article>
            </section>
          </Col>
        </Row>
       {notice}
       <Row className='button-action text-center'>
          <Col className='text-group' lg={12}>
            <Link to='/authorization'>
              <Button variant='success' size='lg' className='mr-2'>
                <i className='material-icons'>playlist_add</i>Start Authorization
              </Button>
            </Link>
            <Link to='/individuals'>
              <Button variant='success' size='lg'>
                <i className='material-icons'>fingerprint</i>Start Screening
              </Button>
            </Link>
          </Col>
        </Row>

        <OneTimeCheckList />
      </Container>
    );
  }
}

const mapActionsToProps = {
  getOrdersCountByResult,
  getUserWithJwt,
};

const mapStateToProps = state => {
  return {
    ordersCount: state.orderReducer.ordersCount,
    user: state.userReducer.user
  };
};

export default connect(mapStateToProps, mapActionsToProps)(App);
