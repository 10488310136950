import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
// import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';

// export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(),
    preloadedState,
    compose(
      applyMiddleware(
        // routerMiddleware(history), // for dispatching history actions
        thunk,
        logger
      )
    )
  );

  return store;
}
