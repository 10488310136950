import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import './UserPaymentPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserCourtFees,
    updateUserCourtFeesStatus,
    setDateRange,
    setFilteredPayments,
    toggleSelectPayment,
    toggleSelectAll,
    setExportFormat,
    toggleExportModal
} from '../../../actions/courtFeesAction';
import { useLocation } from 'react-router-dom';

const UserPaymentPage = () => {
    const dispatch = useDispatch();
    const {
        userCourtFees,
        loading,
        updatingStatus,
        filteredPayments,
        selectedPayments,
        selectAll,
        dateRange,
        showExportModal,
        exportFormat,
        error
    } = useSelector((state) => state.courtFeesReducer);

    const location = useLocation();
    const userId = location.state?.user?.Id;
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(10);  
    useEffect(() => {
        if (userId) {
            dispatch(getUserCourtFees(userId));
            resetDateFilter()
        }
    }, [dispatch, userId]);

    useEffect(() => {
            dispatch(setFilteredPayments(userCourtFees));
    }, [userId, dispatch]);

    useEffect(() => {
        setCurrentPage(0);
    }, [filteredPayments]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        dispatch(setDateRange({ ...dateRange, [name]: value }));
    };

    const handleCheckboxChange = (paymentId) => {
        dispatch(toggleSelectPayment(paymentId));
    };

    const handleSelectAllChange = () => {
        dispatch(toggleSelectAll(getCurrentPageItems()));
    };

    const markSelectedAsPaid = () => {
        dispatch(updateUserCourtFeesStatus(selectedPayments, userId));
    };

    const applyDateFilter = () => {
        const filtered = userCourtFees?.filter(payment => {
            if (!dateRange.fromDate && !dateRange.toDate) return true;

            const paymentDate = new Date(payment.date_recorded || payment.createdAt);
            const fromDate = dateRange.fromDate ? new Date(dateRange.fromDate) : new Date(0);
            const toDate = dateRange.toDate ? new Date(dateRange.toDate) : new Date();

            paymentDate.setHours(0, 0, 0, 0);
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(23, 59, 59, 999);

            return paymentDate >= fromDate && paymentDate <= toDate;
        });

        dispatch(setFilteredPayments(filtered || []));
    };

    const resetDateFilter = () => {
        dispatch(setDateRange({ fromDate: '', toDate: '' }));
        dispatch(setFilteredPayments(userCourtFees || []));
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const exportData = () => {
        const dataToExport = filteredPayments.map(payment => ({
            ID: payment.id,
            'Court Fee Amount': `$${parseFloat(payment.court_fee_amount / 100).toFixed(2)}`,
            'Date Created': formatDate(payment.createdAt),
            'Status': payment.status === 'PAID' ? 'Paid' : 'Pending'
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments');

        const fileName = `payment_report_${dateRange.fromDate || 'all'}_to_${dateRange.toDate || 'all'}.${exportFormat === 'excel' ? 'xlsx' : 'csv'}`;
        XLSX.writeFile(workbook, fileName);

        dispatch(toggleExportModal(false));
    };

    const getPaymentStatus = (status) => status === 'PAID' ? 'Paid' : 'Pending';

    const isPaymentSelected = (paymentId) => selectedPayments.includes(paymentId);

    const indexOfFirstRecord = currentPage * recordsPerPage;
    const getCurrentPageItems = () => {
        return filteredPayments.slice(indexOfFirstRecord, indexOfFirstRecord + recordsPerPage);
    };
    const currentItems = getCurrentPageItems();
    const pageCount = Math.ceil(filteredPayments.length / recordsPerPage);
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    const hasPendingPayments = currentItems.some(p => getPaymentStatus(p.status) === 'Pending');

    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <Card className='card-box mt-5'>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <Card.Title as="h2" className="page-title">
                                    <i className="bi bi-credit-card-2-front me-2"></i>
                                    User Payments
                                </Card.Title>
                                <div className="d-flex">
                                    <Button
                                        variant="primary"
                                        className="action-btn"
                                        onClick={() => dispatch(toggleExportModal(true))}
                                        disabled={loading || !filteredPayments.length}
                                    >
                                        <i className="bi bi-file-earmark-arrow-down me-1"></i>
                                        Export Report
                                    </Button>
                                </div>
                            </div>

                            {/* Date Filter Controls */}
                            <Card className="filter-card mb-4">
                                <Card.Body>
                                    <Row className="align-items-end">
                                        <Col md={4}>
                                            <Form.Group className="mb-md-0 mb-3">
                                                <Form.Label className="filter-label">From Date</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text className="date-icon">
                                                        <i className="bi bi-calendar3"></i>
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="date"
                                                        name="fromDate"
                                                        value={dateRange.fromDate}
                                                        onChange={handleDateChange}
                                                        className="date-input"
                                                        disabled={loading}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-md-0 mb-3">
                                                <Form.Label className="filter-label">To Date</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text className="date-icon">
                                                        <i className="bi bi-calendar3"></i>
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="date"
                                                        name="toDate"
                                                        value={dateRange.toDate}
                                                        onChange={handleDateChange}
                                                        className="date-input"
                                                        disabled={loading}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className="d-flex filter-buttons">
                                            <Button
                                                variant="primary"
                                                onClick={applyDateFilter}
                                                className='me-2'
                                                disabled={loading}
                                            >
                                                <i className="bi bi-funnel-fill me-1"></i>
                                                Apply Filter
                                            </Button>
                                            <Button
                                                variant="outline-secondary"
                                                onClick={resetDateFilter}
                                                className="reset-btn ml-2"
                                                disabled={loading}
                                            >
                                                <i className="bi bi-arrow-counterclockwise me-1"></i>
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Error Display */}
                            {error && (
                                <div className="alert alert-danger mb-3">
                                    <i className="bi bi-exclamation-triangle me-2"></i>
                                    {error}
                                </div>
                            )}

                            {selectedPayments.length > 0 && !loading && (
                                <Button
                                    variant='outline-primary'
                                    className="action-btn me-2 mb-2"
                                    onClick={markSelectedAsPaid}
                                    disabled={updatingStatus}
                                >
                                    {updatingStatus ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="me-2" />
                                            Updating...
                                        </>
                                    ) : (
                                        <>
                                            <i className="bi bi-check-circle me-1"></i>
                                            Mark Selected as Paid
                                        </>
                                    )}
                                </Button>
                            )}

                            {loading ? (
                                <div className="text-center p-5">
                                    <Spinner animation="border" variant="primary" />
                                    <p className="mt-2">Loading payment data...</p>
                                </div>
                            ) : (
                                <>
                                    <div className='overflow-auto'>
                                        <Table className="payment-table">
                                            <thead>
                                                <tr>
                                                    <th className="checkbox-col">
                                                        <Form.Check
                                                            type="checkbox"
                                                            onChange={handleSelectAllChange}
                                                            checked={selectAll}
                                                            title="Select all pending records on this page"
                                                            className="select-all-checkbox"
                                                            disabled={!hasPendingPayments}
                                                        />
                                                    </th>
                                                    <th className="id-col">#</th>
                                                    <th>Court Fee Amount</th>
                                                    <th>Date Created</th>
                                                    <th>Payment Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map(payment => {
                                                    const status = getPaymentStatus(payment.status);
                                                    const isPaid = status === 'Paid';
                                                    return (
                                                        <tr key={payment.id} className={isPaid ? 'paid-row' : ''}>
                                                            <td className="text-center">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    onChange={() => handleCheckboxChange(payment.id)}
                                                                    checked={isPaymentSelected(payment.id) || (selectedPayments.length && payment.status === 'PAID')}
                                                                    disabled={isPaid || updatingStatus}
                                                                    className="record-checkbox"
                                                                />
                                                            </td>
                                                            <td className="id-value">{payment.id}</td>
                                                            <td className="amount-value">${parseFloat((payment.court_fee_amount / 100)).toFixed(2)}</td>
                                                            <td className="date-value">{formatDate(payment.createdAt)}</td>
                                                            <td className="status-col">
                                                                <Badge
                                                                    bg={isPaid ? 'success' : 'warning'}
                                                                    className={`status-badge ${status.toLowerCase()}-badge`}
                                                                >
                                                                    {status}
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>

                                        {filteredPayments.length === 0 && (
                                            <div className="text-center p-3">
                                                <p>No records found for the selected date range.</p>
                                            </div>
                                        )}
                                    </div>

                                    {filteredPayments.length > 0 && (
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <div className="pagination-info">
                                                Showing {indexOfFirstRecord + 1} to {Math.min(indexOfFirstRecord + recordsPerPage, filteredPayments.length)} of {filteredPayments.length} records
                                            </div>
                                            
                                            <ReactPaginate
                                                previousLabel={'< Previous'}
                                                nextLabel={'Next >'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={'pagination'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                breakLinkClassName={'page-link'}
                                                activeClassName={'active'}
                                                disabledClassName={'disabled'}
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showExportModal} onHide={() => dispatch(toggleExportModal(false))} className="export-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="bi bi-file-earmark-arrow-down me-2"></i>
                        Export Payment Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4">
                            <Form.Label className="export-label">Export Format</Form.Label>
                            <div className="format-selector">
                                <div
                                    className={`format-option ${exportFormat === 'excel' ? 'selected' : ''}`}
                                    onClick={() => dispatch(setExportFormat('excel'))}
                                >
                                    <i className="bi bi-file-earmark-excel format-icon"></i>
                                    <div className="format-details">
                                        <div className="format-name">Excel</div>
                                        <div className="format-ext">.xlsx format</div>
                                    </div>
                                </div>
                                <div
                                    className={`format-option ${exportFormat === 'csv' ? 'selected' : ''}`}
                                    onClick={() => dispatch(setExportFormat('csv'))}
                                >
                                    <i className="bi bi-file-earmark-text format-icon"></i>
                                    <div className="format-details">
                                        <div className="format-name">CSV</div>
                                        <div className="format-ext">.csv format</div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                        <div className="export-summary">
                            <div className="summary-icon">
                                <i className="bi bi-info-circle"></i>
                            </div>
                            <div className="summary-text">
                                <p>You are about to export <strong>{filteredPayments.length} payment records</strong>.</p>
                                {dateRange.fromDate && dateRange.toDate && (
                                    <p className="date-range-info">Date range: <strong>{dateRange.fromDate}</strong> to <strong>{dateRange.toDate}</strong></p>
                                )}
                                {!dateRange.fromDate && !dateRange.toDate && (
                                    <p className="date-range-info">No date filter applied. All records will be exported.</p>
                                )}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className="cancel-btn" onClick={() => dispatch(toggleExportModal(false))}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="export-btn" onClick={exportData}>
                        <i className="bi bi-download me-1"></i>
                        Export {filteredPayments.length} Records
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default UserPaymentPage;