const config = {
  trueFingerPrintsAPI: {
    baseURL:
      process.env.REACT_APP_STAGE === 'prod'
        ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}/api/v1`
        : `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_API_PORT}/api/v1`
  },
  OrderFees: {
    OneTimeCheck: 39,
  }
};

export default config;
