import {
  GET_USERS_COURT_FEES_REQUEST,
  GET_USERS_COURT_FEES_SUCCESS,
  GET_USERS_COURT_FEES_FAILURE,
  UPDATE_USERS_COURT_FEES_STATUS_REQUEST,
  UPDATE_USERS_COURT_FEES_STATUS_SUCCESS,
  UPDATE_USERS_COURT_FEES_STATUS_FAILURE,
  SET_DATE_RANGE,
  SET_FILTERED_PAYMENTS,
  TOGGLE_SELECT_PAYMENT,
  TOGGLE_SELECT_ALL,
  SET_EXPORT_FORMAT,
  TOGGLE_EXPORT_MODAL
} from '../common/constants';

const initialState = {
  userCourtFees: [],
  filteredPayments: [],
  selectedPayments: [],
  selectAll: false,
  loading: false,
  updatingStatus: false,
  error: null,
  dateRange: {
    fromDate: '',
    toDate: ''
  },
  exportFormat: 'excel',
  showExportModal: false
};

export default (state = initialState, action) => {    
  switch (action.type) {
    case GET_USERS_COURT_FEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_USERS_COURT_FEES_SUCCESS:
      return {
        ...state,
        userCourtFees: action.courtFees,
        loading: false,
        error: null
      };
    case GET_USERS_COURT_FEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case UPDATE_USERS_COURT_FEES_STATUS_REQUEST:
      return {
        ...state,
        updatingStatus: true,
        error: null
      };
    case UPDATE_USERS_COURT_FEES_STATUS_SUCCESS:
      return {
        ...state,
        updatingStatus: false,
        selectedPayments: [],
        selectAll: false,
        error: null
      };
    case UPDATE_USERS_COURT_FEES_STATUS_FAILURE:
      return {
        ...state,
        updatingStatus: false,
        error: action.error
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange
      };
    case SET_FILTERED_PAYMENTS:
      return {
        ...state,
        filteredPayments: action.payments.map(payment => ({
          ...payment,
          status: payment.status
        }))
      };
    case TOGGLE_SELECT_PAYMENT:
      const isSelected = state.selectedPayments.includes(action.paymentId);
      return {
        ...state,
        selectedPayments: isSelected
          ? state.selectedPayments.filter(id => id !== action.paymentId)
          : [...state.selectedPayments, action.paymentId],
        selectAll: isSelected
          ? false
          : state.filteredPayments.filter(p => p.status !== 'PAID').length === 
            state.selectedPayments.length + 1
      };
    case TOGGLE_SELECT_ALL:
      const pendingPaymentIds = action.payments
        .filter(payment => payment.status !== 'PAID')
        .map(payment => payment.id);
      return {
        ...state,
        selectedPayments: state.selectAll ? [] : pendingPaymentIds,
        selectAll: !state.selectAll
      };
    case SET_EXPORT_FORMAT:
      return {
        ...state,
        exportFormat: action.format
      };
    case TOGGLE_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: action.showModal
      };
    default:
      return state;
  }
}