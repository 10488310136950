import React, { useEffect } from 'react';
import { Container, Row, Col, ListGroup, Table } from 'react-bootstrap';
import Case from '../Cases/case';
import { getOrderDetails } from '../../actions/orderActions';
import { getSubject } from '../../actions/subjectActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const SubjectsHistoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const details = useSelector(state => state.orderReducer.details);
  const subject = useSelector(state => state.subjectReducer.subject);
  const currentOrderId = useSelector(state => state.orderReducer.currentOrderId);
  const currentSubjectId = useSelector(state => state.subjectReducer.currentSubjectId);

  useEffect(() => {
    if (currentOrderId && currentSubjectId) {
      dispatch(getOrderDetails(currentOrderId));
      dispatch(getSubject(currentSubjectId));
    } else {
      if (location && location.state.currentOrderId && location.state.currentSubjectId) {
        dispatch(getOrderDetails(location.state.currentOrderId));
        dispatch(getSubject(location.state.currentSubjectId));
      }
    }
  }, [dispatch, currentOrderId, currentSubjectId, location.state]);


  return (
    <Container>
      <Row>
        <Col lg={12}>
          <section className='subject-full-name mt-5'>
            <h1>
              {subject ? subject.name_first : null}{' '}
              {subject && subject.name_middle
                ? subject.name_middle
                : null}{' '}
              {subject ? subject.name_last : null}
            </h1>
          </section>
        </Col>
      </Row>

      {(localStorage.getItem('userRole') &&
        localStorage.getItem('userRole') !== 'user') ||
        (sessionStorage.getItem('userRole') &&
          sessionStorage.getItem('userRole') !== 'user') ? (
          <Row className='mt-4'>
            <Col lg={6}>
              <article className='results-component header-info'>
                <ListGroup variant=''>
                  <ListGroup.Item>
                    Status:
                  <span className='pl-2'>
                      {details ? details.status : null}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Age:<span className='pl-2'>0.00s</span>
                  </ListGroup.Item>
                </ListGroup>
              </article>
            </Col>
            <Col lg={6}>
              <article className='results-component header-info'>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    Order type:
                  <span className='pl-2'>
                      {details
                        ? details.order_type
                        : null}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Service name:
                  <span className='pl-2'>
                      {details
                        ? details.service_name
                        : null}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </article>
            </Col>
          </Row>
        ) : null}

      <Row className='mt-5'>
        <Col lg={6}>
          <article className='results-component order-info mb-5'>
            <ListGroup variant='flush'>
              <h5 className='ml-3 mb-4 mt-3'>Order Information</h5>
              <ListGroup.Item>
                Order ID:
                <span className='pl-2'>
                  {details ? details.order_id : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Service:
                <span className='pl-2'>
                  {details
                    ? details.service_name
                    : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                State:
                <span className='pl-2'>
                  {details && details.state
                    ? details.state
                    : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                County:
                <span className='pl-2'>
                  {details && details.county
                    ? details.county
                    : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Scope of Search:
                <span className='pl-2'>
                  {details ? details.order_type : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Created:
                <span className='pl-2'>
                  {details && details.created_date
                    ? details.created_date
                    : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Completed:
                <span className='pl-2'>
                  {details
                    ? details.completed_date
                    : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Cases:
                <span className='pl-2'>
                  {' '}
                  {details &&
                    details.cases &&
                    details.cases.length > 0
                    ? details.cases.length
                    : 'No cases have been found'}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </article>
        </Col>
        <Col lg={6}>
          <article className='results-component subject-info mb-5'>
            <ListGroup variant='flush'>
              <h5 className='ml-3 mb-4 mt-3'>Individual Information</h5>
              <ListGroup.Item>
                First Name:
                <span className='pl-2'>
                  {' '}
                  {subject
                    ? subject.name_first
                    : null}{' '}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                Last Name:
                <span className='pl-2'>
                  {' '}
                  {subject ? subject.name_last : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                DOB:
                <span className='pl-2'>
                  {' '}
                  {subject ? subject.dob : null}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                SSN:
                <span className='pl-2'>
                  {' '}
                  {subject ? subject.ssn : null}
                </span>
              </ListGroup.Item>

            </ListGroup>
          </article>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <h3 className='text-center box title-box-primary'>Cases</h3>
        </Col>
      </Row>
      {details && details.cases ? (
        <Case details={details.cases} />
      ) : null}

      {(localStorage.getItem('userRole') &&
        localStorage.getItem('userRole') !== 'user') ||
        (sessionStorage.getItem('userRole') &&
          sessionStorage.getItem('userRole') !== 'user') ? (
          <Row>
            <Col lg={12}>
              <h5 className='text-center box title-box-quaternary'>Notes</h5>
            </Col>
          </Row>
        ) : null}

      {(localStorage.getItem('userRole') &&
        localStorage.getItem('userRole') !== 'user') ||
        (sessionStorage.getItem('userRole') &&
          sessionStorage.getItem('userRole') !== 'user') ? (
          <Row>
            <Col lg={12}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Note type</th>
                    <th>Note</th>
                    <th>Note Date</th>
                  </tr>
                </thead>
                <tbody>
                  {details && details.notes
                    ? details.notes.map(n => {
                      return (
                        <tr key={n.note_id}>
                          <td>{n.note_type}</td>
                          <td>{n.note}</td>
                          <td>{n.timestamp}</td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : null}
    </Container>
  );
}

export default SubjectsHistoryPage;
