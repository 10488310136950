import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OneTimeIcon from '../../../src/images/OneTimeIcon.png';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import {
  getOneTimeCheckList,
  setCurrentOrderId
} from '../../actions/orderActions';
import { setCurrentSubjectId } from '../../actions/subjectActions';
import actionHelpers from '../../common/actionHelpers';
import enums from '../../common/enums';
import dateFormat from 'dateformat';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

class OneTimeCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneTimeSearchText: '',
      redirect: false
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.findOrders = this.findOrders.bind(this);
    this.setOrderAndSubject = this.setOrderAndSubject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  findOrders() {
    this.loadOrders(1);
  }

  handlePageClick = e => {
    this.loadOrders(e.selected + 1);
  };

  componentDidMount() {
    this.loadOrders(1);
  }

  handleTextChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  loadOrders(page) {
    const req = {
      page: page,
      orderType: enums.OrderTypes.oneTimeCheck,
      searchText: this.state.oneTimeSearchText
    };
    this.props.getOneTimeCheckList(req);
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  setOrderAndSubject(order_id, subject_id) {
    this.props.setCurrentOrderId(order_id);
    this.props.setCurrentSubjectId(subject_id);
    this.setState({
      redirect: true
    });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to='/history' />;
    }
    return (
      <Row className='table-section'>
        <Col lg={12}>
          <figure className='icon-group'>
            <img src={OneTimeIcon} alt='One-Time Check Icon' />
            <h2 className='heading ml-3'>PreHire List</h2>
          </figure>

          <Form onSubmit={this.handleSubmit}>
            <FormControl
              type='text'
              placeholder='Search Individuals'
              className='mr-sm-2'
              onChange={this.handleTextChange}
              name='oneTimeSearchText'
            />
            <Button variant='outline-dark mb-3 mt-3' onClick={this.findOrders}>
              Find
            </Button>
          </Form>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Full Name</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.oneTimeCheckList
                  ? this.props.oneTimeCheckList.orders.map(i => {
                      return (
                        <tr key={i.orderId}>
                          <td>{dateFormat(i.date, 'mmm dd yyyy')}</td>
                          <td>{`${i.subjectName} ${
                            i.subjectMiddleName ? i.subjectMiddleName : ''
                          } ${i.subjectLatName}`}</td>
                          <td>{`${i.city} ${i.state.split('-').pop()} ${
                            i.zip
                          }`}</td>
                          <td>
                            {actionHelpers.renderOrderStatus(i.orderResult)}
                          </td>
                          <td>
                            {i.orderResult !== 'Awaiting' ? (
                              <Link
                                to={'/history'}
                                state={{
                                  currentOrderId: i.orderId,
                                  currentSubjectId: i.subject_id
                                }}
                              >
                                <Button variant='primary'>
                                  <i className='material-icons'>pageview</i>
                                  View Results
                                </Button>
                              </Link>
                            ) : (
                              <Button variant='primary' href='#' disabled>
                                <i className='material-icons'>pageview</i>
                                Pending
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Previous'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.props.oneTimeCheckList &&
                this.props.oneTimeCheckList.ordersCount
                  ? Math.ceil(this.props.oneTimeCheckList.ordersCount / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    );
  }
}

const mapActionsToProps = {
  getOneTimeCheckList: getOneTimeCheckList,
  setCurrentOrderId: setCurrentOrderId,
  setCurrentSubjectId: setCurrentSubjectId
};
const mapStateToProps = state => {
  return {
    oneTimeCheckList: state.orderReducer.oneTimeCheckList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(OneTimeCheckList);
