import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router';

export const getUserFromJWT = () => {
  const ssToken = window.sessionStorage.getItem('jwt');
  const lsToken = window.localStorage.getItem('jwt');

  if (ssToken) {
    return jwtDecode(ssToken);
  }
  if (lsToken) {
    return jwtDecode(lsToken);
  }
};


export const withRouter = (Component) =>{
  const Wrapper = (props) =>{
      const history = useNavigate();
      return <Component history={history} {...props}/>
  } 
  return Wrapper;
}
