import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../../common/textFieldGroup';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { editEmployee } from '../../../actions/employeeActions';
import { withRouter } from '../../../common/utils';
import { getUserWithJwt } from '../../../actions/userActions';
import validateEditEmployee from '../../../validations/editEmployeeValidation';
import ToastService from '../../Toasts/toastService';
import Loader from '../../Spinners/spinnerPage';

class EditEmployeePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeId: '',
      fullName: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: '',
      errors: {},
      isValid: {},
      oldEmail: '',
      showLoader: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ showLoader: true })
    try {
      const isValid = await this.isValid();
      if (isValid) {
        this.setState({ errors: {} });
        this.props.editEmployee({ ...this.state });
      }
      this.setState({ showLoader: false });
      ToastService.show('Successfully edited employee!', 'success');
    } catch (error) {
      // handle error
      console.log('error', error.response);
      this.setState({ showLoader: false });
      ToastService.show(`${error.response.statusText},please contact customer support.`, 'error');
    }
  }

  async isValid() {
    try {
      let { errors, isValid } = await validateEditEmployee(
        this.state,
        this.state.oldEmail
      );

      if (!isValid) {
        this.setState({ errors });
      }

      return isValid;
    } catch (error) { }
  }

  componentDidMount() {
    this.props.getUserWithJwt(this.props.location.state.employeeId);
  }

  componentWillMount() {
    if (localStorage.getItem('userRole') && localStorage.getItem('userRole') !== 'sysadmin') {
      window.location.href = '/error';
    }
    else if (sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole') !== 'sysadmin') {
      window.location.href = '/error';
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user) {
      this.setState({
        fullName: newProps.user.fullName,
        phone: newProps.user.phone,
        email: newProps.user.email,
        employeeId: newProps.user.Id,
        oldEmail: newProps.user.email
      });
    }
  }

  showToast() {
    ToastService.show('Successfully edited employee!', 'success');
  }


  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <Card className='card-box mt-5'>
              <Form onSubmit={this.onSubmit}>
                <Card.Body>
                  <Card.Title>
                    <h2>
                      <i className='material-icons md-36'>person_add</i>Edit
                      Employee
                    </h2>
                  </Card.Title>
                  <h5 className='mt-5 mb-3'>Employee's Personal Info</h5>
                  <Card.Text />
                  <TextFieldGroup
                    error={this.state.errors.fullName}
                    label='Full Name'
                    onChange={this.onChange}
                    value={this.state.fullName}
                    field='fullName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.email}
                    label='Email address'
                    onChange={this.onChange}
                    value={this.state.email}
                    field='email'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.password}
                    label='Password'
                    onChange={this.onChange}
                    value={this.state.password}
                    field='password'
                    required={false}
                    type='password'
                  />
                  <TextFieldGroup
                    error={this.state.errors.passwordConfirmation}
                    label='Confirm password'
                    onChange={this.onChange}
                    value={this.state.passwordConfirmation}
                    field='passwordConfirmation'
                    required={false}
                    type='password'
                  />
                  <TextFieldGroup
                    error={this.state.errors.phone}
                    label='Phone'
                    onChange={this.onChange}
                    value={this.state.phone}
                    field='phone'
                  />
                  {loader}
                  <Button variant='primary' className='mt-3 mb-2' type='submit'>
                    <i className='material-icons'>person_add</i>
                    Edit Employee
                  </Button>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  editEmployee,
  getUserWithJwt
};

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(EditEmployeePage)
);
