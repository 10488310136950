import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../../common/textFieldGroup';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { updateUserByAdmin } from '../../../actions/userActions';
import validateEditUser from '../../../validations/editUserValidation';
import ToastService from '../../Toasts/toastService';
import Loader from '../../Spinners/spinnerPage';

const EditUserPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    userId: '',
    fullName: '',
    email: '',
    phone: '',
    errors: {},
    isValid: {},
    oldEmail: '',
    showLoader: false,
    preHireCharge: 0,
    preHireLimit: 0,
  });

  useEffect(() => {
    if (
      localStorage.getItem('userRole') &&
      localStorage.getItem('userRole') !== 'sysadmin'
    ) {
      navigate('/error');
    } else if (
      sessionStorage.getItem('userRole') &&
      sessionStorage.getItem('userRole') !== 'sysadmin'
    ) {
      navigate('/error');
    }

    if (location.state && location.state.user) {
      const { user } = location.state;
      setState(prevState => ({
        ...prevState,
        fullName: user.fullName,
        phone: user.phone,
        email: user.email,
        preHireCharge: user.preHireCharge,
        preHireLimit: user.preHireLimit,
      }));
    }
  }, [location.state, navigate]);

  const onChange = e => {
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, showLoader: true }));
    try {
      const valid = await isValid();
      if (valid) {
        setState(prevState => ({ ...prevState, errors: {} }));
        dispatch(
          updateUserByAdmin({
            fullName: state.fullName,
            email: state.email,
            phone: state.phone,
            id: location.state.user.Id,
            preHireCharge: state.preHireCharge,
            preHireLimit: state.preHireLimit,
          })
        );
      }
      setState(prevState => ({ ...prevState, showLoader: false }));
      ToastService.show('Successfully edited user!', 'success');
      navigate('/users');
    } catch (error) {
      console.log('error', error.response);
      setState(prevState => ({ ...prevState, showLoader: false }));
      ToastService.show(
        `${error.response.statusText}, please contact customer support.`,
        'error'
      );
    }
  };

  const isValid = async () => {
    try {
      let { errors, isValid } = await validateEditUser(state, state.oldEmail);

      if (!isValid) {
        setState(prevState => ({ ...prevState, errors }));
      }

      return isValid;
    } catch (error) {
      console.error(error);
    }
  };
  const loader = state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <Card className='card-box mt-5'>
              <Form onSubmit={onSubmit}>
                <Card.Body>
                  <Card.Title>
                    <h2>
                      <i className='material-icons md-36'>person_add</i>Edit
                      User
                    </h2>
                  </Card.Title>
                  <h5 className='mt-5 mb-3'>User's Personal Info</h5>
                  <Card.Text />
                  <TextFieldGroup
                    error={state.errors.fullName}
                    label='Full Name'
                    onChange={onChange}
                    value={state.fullName}
                    field='fullName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={state.errors.email}
                    label='Email address'
                    onChange={onChange}
                    value={state.email}
                    field='email'
                    required={true}
                  />
                  <TextFieldGroup
                    error={state.errors.phone}
                    label='Phone'
                    onChange={onChange}
                    value={state.phone}
                    field='phone'
                  />
                  <TextFieldGroup
                    label='PreHire Charge'
                    onChange={onChange}
                    value={state.preHireCharge}
                    field='preHireCharge'
                    type='number'
                  />
                  <TextFieldGroup
                    label='PreHire Limits'
                    onChange={onChange}
                    value={state.preHireLimit}
                    field='preHireLimit'
                    type='number'
                  />
                  {loader}
                  <Button variant='primary' className='mt-3 mb-2' type='submit'>
                    <i className='material-icons'>person_add</i>
                    Edit User
                  </Button>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
export default EditUserPage;