export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const ADD_SUBJECT_SUCCESS = 'ADD_SUBJECT_SUCCESS';
export const GET_USER_CARDS_SUCCESS = 'GET_USER_CARDS_SUCCESS';
export const GET_ONE_TIME_CHECK_LIST = 'GET_ONE_TIME_CHECK_LIST';
export const RUN_ONE_TIME_CHECK = 'RUN_ONE_TIME_CHECK';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const SET_CURRENT_SUBJECT_ID = 'SET_CURRENT_SUBJECT_ID';
export const SET_CURRENT_ORDER_ID = 'SET_CURRENT_ORDER_ID';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECT_ORDER_SUCCESS = 'GET_SUBJECT_ORDER_SUCCESS';
export const GET_SUBJECT_SUMMARY_SUCCESS = 'GET_SUBJECT_SUMMARY_SUCCESS';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const GET_SUBJECTS_ADMIN_SUCCESS = 'GET_SUBJECTS_ADMIN_SUCCESS';
export const GET_ORDERS_BY_RESULT_SUCCESS = 'GET_ORDERS_BY_RESULT_SUCCESS';
export const COUNT_ORDERS_BY_RESULT_SUCCESS = 'COUNT_ORDERS_BY_RESULT_SUCCESS';
export const GET_USER_TRANSACTIONS_SUCCESS = 'GET_USER_TRANSACTIONS_SUCCESS';
export const ADD_SUBJECT_ERROR = 'ADD_SUBJECT_ERROR';
export const GET_USER_PROCESSING_ORDERS_SUCCESS = 'GET_USER_PROCESSING_ORDERS_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const UPDATE_USER_BY_ADMIN_SUCCESS = 'UPDATE_USER_BY_ADMIN_SUCCESS';
export const ADD_AUTH_SUCCESS = 'ADD_AUTH_SUCCESS';
export const GET_AUTHS_SUCCESS = 'GET_AUTHS_SUCCESS';
export const GET_AUTHORIZED_AUTHS_SUCCESS = 'GET_AUTHORIZED_AUTHS_SUCCESS';
export const ADD_SUBJECT_AUTH_SUCCESS = 'ADD_SUBJECT_AUTH_SUCCESS';
export const GET_SUBJECT_AUTH_SUCCESS = 'GET_SUBJECT_AUTH_SUCCESS';
export const GET_USERS_COURT_FEES_REQUEST = 'GET_USERS_COURT_FEES_REQUEST';
export const GET_USERS_COURT_FEES_SUCCESS = 'GET_USERS_COURT_FEES_SUCCESS';
export const GET_USERS_COURT_FEES_FAILURE = 'GET_USERS_COURT_FEES_FAILURE';
export const UPDATE_USERS_COURT_FEES_STATUS_REQUEST = 'UPDATE_USERS_COURT_FEES_STATUS_REQUEST';
export const UPDATE_USERS_COURT_FEES_STATUS_SUCCESS = 'UPDATE_USERS_COURT_FEES_STATUS_SUCCESS';
export const UPDATE_USERS_COURT_FEES_STATUS_FAILURE = 'UPDATE_USERS_COURT_FEES_STATUS_FAILURE';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_FILTERED_PAYMENTS = 'SET_FILTERED_PAYMENTS';
export const TOGGLE_SELECT_PAYMENT = 'TOGGLE_SELECT_PAYMENT';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const SET_EXPORT_FORMAT = 'SET_EXPORT_FORMAT';
export const TOGGLE_EXPORT_MODAL = 'TOGGLE_EXPORT_MODAL';

