import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import EmployeesIcon from '../../../images/EmployeesIcon.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { getUsers } from '../../../actions/userActions';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

class UsersListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      searchText: ''
    };
    this.loadUsers = this.loadUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.loadUsers(1);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePageClick = e => {
    this.loadUsers(e.selected + 1);
  };
  loadUsers(page) {
    const req = {
      page: page,
      searchText: this.state.searchText
    };
    this.props.getUsers(req);
  }

  componentWillMount() {
    if (
      localStorage.getItem('userRole') &&
      localStorage.getItem('userRole') !== 'sysadmin'
    ) {
      window.location.href = '/error';
    } else if (
      sessionStorage.getItem('userRole') &&
      sessionStorage.getItem('userRole') !== 'sysadmin'
    ) {
      window.location.href = '/error';
    }
  }

  render() {
    return (
      <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item>
                <Link to='/'>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Employees</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className='table-section'>
          <Col lg={12}>
            <figure className='icon-group mt-5'>
              <img src={EmployeesIcon} alt='Employees List Icon' />
              <h2 className='heading ml-3'>Users List</h2>
            </figure>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search for User'
                className='mr-sm-2'
                name='searchText'
                onChange={this.handleChange}
              />
              <Button
                variant='outline-dark mb-3 mt-3'
                onClick={() => this.loadUsers(1)}
              >
                Find
              </Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>PreHire Charge</th>
                    <th>PreHire Limit</th>
                    <th colSpan='2'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.usersList
                    ? this.props.usersList.rows.map(i => {
                        return (
                          <tr key={i.Id}>
                            <td>{i.fullName}</td>
                            <td>{i.email}</td>
                            <td>{i.phone}</td>
                            <td>
                              {i.preHireCharge != null ? i.preHireCharge : 0}
                            </td>
                            <td>{i.preHireLimit}</td>
                            <td>
                              <div>
                              <Link
                                to={'/editUser'}
                                state={{ user: i}}
                              >
                                <Button variant='outline-primary'>
                                  <i className='material-icons'>edit</i>
                                  Edit
                                </Button>
                              </Link>
                              <Link
                                to={'/paymentsUser'}
                                state={{ user: i}}
                                className='ml-2'
                              >
                                <Button variant='outline-primary'>
                                  {/* <i className='material-icons mr-0'>payments</i> */}
                                  Payments
                                </Button>
                              </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.usersList
                    ? Math.ceil(this.props.usersList.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getUsers: getUsers
};

const mapStateToProps = state => {
  return {
    usersList: state.userReducer.usersList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(UsersListPage);
