import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../common/textFieldGroup';
import SelectFieldGroup from '../common/selectFieldGroup';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { editSubject, getSubject } from '../../actions/subjectActions';
import validateAddSubject from '../../validations/addSubjectValidation';
import { withRouter } from '../../common/utils';
import States from '../../static/states.json';
import SelectFieldGroupFromArray from '../common/selectFieldGroupFromArray';
import Counties from '../../static/counties.json';
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';

const EditSubjectPage = (props) => {
  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subjectReducer.subject);

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    dob: '',
    ssn: '',
    gender: '',
    city: '',
    state: '',
    county: '',
    errors: {},
    isValid: {},
    counties: [],
    address1: '',
    address2: '',
    email: '',
    phone: '',
    zip: '',
    country: 'US',
    subject_id: '',
    showLoader: false,
  });

  const location = useLocation();

  useEffect(() => {
    dispatch(getSubject(location.state.currentSubjectId));
  }, [dispatch, location.state.currentSubjectId]);

  useEffect(() => {
    if (subject) {
      setState((prevState) => ({
        ...prevState,
        subject_id: subject.subject_id,
        firstName: subject.name_first,
        lastName: subject.name_last,
        middleName: subject.name_middle,
        dob: subject.dob,
        ssn: subject.ssn ? subject.ssn : '',
        gender: JSON.parse(subject.json_result).gender,
        city: subject.city,
        state: subject.state.includes("-") ? subject.state.split("-")[1] : subject.state,
        country: subject.country,
        county: subject.county,
        address1: subject.address1,
        address2: subject.address2,
        email: subject.email ? subject.email : '',
        phone: subject.phone,
        zip: subject.zip,
      }));
      loadCounties(subject.state);
    }
  }, [subject]);

  const onChange = (e) => {
    if (e.target.name === 'state') {
      loadCounties(e.target.value);
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const loadCounties = (state) => {
    const subjectState = state.includes("-") ? state.split("-")[1] : state;
    setState((prevState) => ({
      ...prevState,
      counties: Counties[subjectState],
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      showLoader: true,
    }));

    try {
      if (isValid()) {
        dispatch(editSubject({ ...state, gender: state.gender }));
      }
      setState((prevState) => ({
        ...prevState,
        showLoader: false,
      }));
      ToastService.show('Successfully edited individual!', 'success');
    } catch (error) {
      console.log('error', error);
      setState((prevState) => ({
        ...prevState,
        showLoader: false,
      }));
      ToastService.show(`${error.response.statusText}, please contact customer support.`, 'error');
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateAddSubject(state);

    if (!isValid) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }

    return isValid;
  };

  const loader = state.showLoader ? <Loader /> : null;

  return (
    <Container >
      <Row>
        <Col lg={12}>
          <Card className='card-box mt-5'>
            <Form onSubmit={onSubmit}>
              <Card.Body>
                <Card.Title>
                  <h2>
                    <i className='material-icons md-36'>edit</i>Edit Individual
                  </h2>
                </Card.Title>
                <h5 className='mt-5 mb-3'>Individual's Personal Info</h5>
                <Card.Text />
                <TextFieldGroup
                  error={state.errors.firstName}
                  label='First name'
                  onChange={onChange}
                  value={state.firstName}
                  field='firstName'
                  required={true}
                />
                <TextFieldGroup
                  error={state.errors.lastName}
                  label='Last name'
                  onChange={onChange}
                  value={state.lastName}
                  field='lastName'
                  required={true}
                />
                <TextFieldGroup
                  error={state.errors.middleName}
                  label='Middle name'
                  onChange={onChange}
                  value={state.middleName}
                  field='middleName'
                />
                <TextFieldGroup
                  error={state.errors.dob}
                  label='Date of Birth (Format: YYYY-MM-DD)'
                  onChange={onChange}
                  value={state.dob}
                  field='dob'
                  required={true}
                />
                <TextFieldGroup
                  error={state.errors.ssn}
                  label='SSN (Format: AAA-BB-CCCC)'
                  onChange={onChange}
                  value={state.ssn}
                  field='ssn'
                />
                <TextFieldGroup
                  error={state.errors.address1}
                  label='Address 1'
                  onChange={onChange}
                  value={state.address1}
                  field='address1'
                />
                <TextFieldGroup
                  error={state.errors.address2}
                  label='Address 2'
                  onChange={onChange}
                  value={state.address2}
                  field='address2'
                />
                <SelectFieldGroup
                  error={state.errors.gender}
                  label='Gender'
                  onChange={onChange}
                  value={state.gender}
                  required={true}
                  name='gender'
                />
                <TextFieldGroup
                  error={state.errors.city}
                  label='City'
                  onChange={onChange}
                  value={state.city}
                  field='city'
                />
                <SelectFieldGroupFromArray
                  error={state.errors.state}
                  label='State'
                  onChange={onChange}
                  value={state.state}
                  field='state'
                  options={States.Values}
                  name='state'
                  placeholder='- - Select state - -'
                  required={true}
                />
                <SelectFieldGroupFromArray
                  error={state.errors.county}
                  label='County'
                  onChange={onChange}
                  value={state.county}
                  options={state.counties}
                  field='county'
                  name='county'
                  placeholder='- - Select county - -'
                  required={true}
                />
                <TextFieldGroup
                  error={state.errors.zip}
                  label='Zip'
                  onChange={onChange}
                  value={state.zip}
                  field='zip'
                />
                <TextFieldGroup
                  error={state.errors.phone}
                  label='Phone'
                  onChange={onChange}
                  value={state.phone}
                  field='phone'
                />
                <TextFieldGroup
                  error={state.errors.email}
                  label='Email'
                  onChange={onChange}
                  value={state.email}
                  field='email'
                />
                {loader}
                <Button
                  variant='primary'
                  className='mt-3 mb-2'
                  onClick={onSubmit}
                >
                  <i className='material-icons'>loop</i>Update Individual's Info
                </Button>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(EditSubjectPage);
