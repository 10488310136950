import {
    GET_USERS_COURT_FEES_REQUEST,
    GET_USERS_COURT_FEES_SUCCESS,
    GET_USERS_COURT_FEES_FAILURE,
    UPDATE_USERS_COURT_FEES_STATUS_REQUEST,
    UPDATE_USERS_COURT_FEES_STATUS_SUCCESS,
    UPDATE_USERS_COURT_FEES_STATUS_FAILURE,
    SET_DATE_RANGE,
    SET_FILTERED_PAYMENTS,
    TOGGLE_SELECT_PAYMENT,
    TOGGLE_SELECT_ALL,
    SET_EXPORT_FORMAT,
    TOGGLE_EXPORT_MODAL
} from '../common/constants';
import apiCallsHelper from '../api/apiCallsHelper';
import config from '../common/config';

export function getUserCourtFeesRequest() {
    return {
        type: GET_USERS_COURT_FEES_REQUEST
    };
}

export function getUserCourtFeesSuccess(courtFees) {
    return {
        type: GET_USERS_COURT_FEES_SUCCESS,
        courtFees
    };
}

export function getUserCourtFeesFailure(error) {
    return {
        type: GET_USERS_COURT_FEES_FAILURE,
        error
    };
}

export function updateUserCourtFeesStatusRequest() {
    return {
        type: UPDATE_USERS_COURT_FEES_STATUS_REQUEST
    };
}

export function updateUserCourtFeesStatusSuccess(courtFees) {
    return {
        type: UPDATE_USERS_COURT_FEES_STATUS_SUCCESS,
        courtFees
    };
}

export function updateUserCourtFeesStatusFailure(error) {
    return {
        type: UPDATE_USERS_COURT_FEES_STATUS_FAILURE,
        error
    };
}

export function setDateRange(dateRange) {
    return {
        type: SET_DATE_RANGE,
        dateRange
    };
}

export function setFilteredPayments(payments) {
    return {
        type: SET_FILTERED_PAYMENTS,
        payments
    };
}

export function toggleSelectPayment(paymentId) {
    return {
        type: TOGGLE_SELECT_PAYMENT,
        paymentId
    };
}

export function toggleSelectAll(payments) {
    return {
        type: TOGGLE_SELECT_ALL,
        payments
    };
}

export function setExportFormat(format) {
    return {
        type: SET_EXPORT_FORMAT,
        format
    };
}

export function toggleExportModal(showModal) {
    return {
        type: TOGGLE_EXPORT_MODAL,
        showModal
    };
}

export function getUserCourtFees(clientID) {
    return function (dispatch) {
        dispatch(getUserCourtFeesRequest());

        return apiCallsHelper
            .getWithJWT(`${config.trueFingerPrintsAPI.baseURL}/court-fees/client/${clientID}`)
            .then(r => {
                dispatch(getUserCourtFeesSuccess(r.items));
                dispatch(setFilteredPayments(r.items));
                return r.items;
            })
            .catch(err => {
                dispatch(getUserCourtFeesFailure(err.message || 'Failed to fetch court fees.'));
                throw err;
            });
    };
}

export function updateUserCourtFeesStatus(paymentIds, clientID) {
    return function (dispatch) {
        dispatch(updateUserCourtFeesStatusRequest());

        return apiCallsHelper
            .patchWithJWT(`${config.trueFingerPrintsAPI.baseURL}/court-fees/batch`, paymentIds)
            .then(r => {
                dispatch(updateUserCourtFeesStatusSuccess(r.data));
                dispatch(getUserCourtFees(clientID));
                return r.data;
            })
            .catch(err => {
                dispatch(updateUserCourtFeesStatusFailure(err.message || 'Failed to update payment status.'));
                throw err;
            });
    };
}