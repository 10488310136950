import axios from 'axios';

class ApiHelper {
  async get(url, req) {
    try {
      const result = await axios.get(url, req);
      return result;
    } catch (error) {
      throw new error(error);
    }
  }

  async getWithJWT(url) {
    try {
      const token = localStorage.getItem('jwt')
        ? localStorage.getItem('jwt')
        : sessionStorage.getItem('jwt');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const result = await axios.get(url, config);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async postWithJWT(url, req) {
    try {
      const token = localStorage.getItem('jwt')
        ? localStorage.getItem('jwt')
        : sessionStorage.getItem('jwt');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const result = await axios.post(url, req, config);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async post(url, req) {
    try {
      const result = await axios.post(url, req);
      return result;
    } catch (error) {
      throw new error(error);
    }
  }

  async putWithJWT(url, req) {
    try {
      const token = localStorage.getItem('jwt')
        ? localStorage.getItem('jwt')
        : sessionStorage.getItem('jwt');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const result = await axios.put(url, req, config);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async patchWithJWT(url, req) {
    try {
      const token = localStorage.getItem('jwt')
        ? localStorage.getItem('jwt')
        : sessionStorage.getItem('jwt');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const result = await axios.patch(url, req, config);
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export default new ApiHelper();
