import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import SubjectsIcon from '../../images/SubjectsIcon.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import OneTimeCheckPopUp from '../Modals/OneTimeCheck';
import { Link } from 'react-router-dom';
import { getSubjects } from '../../actions/subjectActions';
import { connect } from 'react-redux';
import { getUserFromJWT } from '../../common/utils';
import ReactPaginate from 'react-paginate';
import UserApi from './../../api/user-api.js';

class SubjectsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      subjectId: '',
      userRole: '',
      currentPage: 1,
      searchText: '',
      state: '',
      county: ''
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadSubjects = this.loadSubjects.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(e) {
    e.preventDefault();
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  showModal(subjectId, state, county) {
    this.setState({ showModal: true, subjectId: subjectId, state, county });
  }
  handlePageClick = e => {
    this.setState({ currentPage: e.selected + 1 });
    this.loadSubjects(e.selected + 1);
  };

  loadSubjects() {
    const req = {
      page: this.state.currentPage,
      searchText: this.state.searchText
    };
    this.props.getSubjects(req);
  }

  async componentDidMount() {
    try {
      this.loadSubjects(1);

      let user = await UserApi.getUserWithJwt();
      let userForRole = getUserFromJWT();

      if (user) {
        this.setState({
          userRole: userForRole.user.role,
          user: user
        });
      }
    } catch (error) { }
  }
  render() {
    return (
      <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item linkAs={Link} linkProps={{to:'/'}}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Individuals</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='button-action intro-text text-center mb-5'>
          <Col lg={12}>
            <h1>Adding Individuals</h1>
            <h5 className='mt-4 mb-5'>
              Individuals are your potential employees or current employees that
              you would like to screen. Before initiating PreHire
              screening, you must add your individuals in the list below, then
              select on the right side which type of screening you would like to
              run on each individual.
            </h5>

            <Link to='/add-subject'>
              <Button variant='success' size='lg'>
                <i className='material-icons'>group_add</i>Add Individuals to
                the List
              </Button>
            </Link>
          </Col>
        </Row>

        <Row className='table-section'>
          <Col lg={12}>
            <figure className='icon-group mt-5'>
              <img src={SubjectsIcon} alt='Individuals List Icon' />
              <h2 className='heading ml-3'>Individuals</h2>
            </figure>
            <Form onSubmit={this.handleSubmit}>
              <FormControl
                type='text'
                placeholder='Search Individuals'
                className='mr-sm-2'
                name='searchText'
                onChange={this.handleChange}
              />
              <Button
                variant='outline-dark mb-3 mt-3'
                onClick={() => this.loadSubjects()}
              >
                Find
              </Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Profile Details</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>State</th>
                    <th>County</th>
                    <th>City</th>
                    <th>Address</th>
                    <th colSpan='2'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.subjectsList
                    ? this.props.subjectsList.rows.map(i => {
                      return (
                        <tr key={i.subject_id}>
                          <td>
                            <Link
                              to={'/view-subject'}
                              state={{ subjectId: i.subject_id }}
                            >
                              <Button variant='link'>
                                <i className='material-icons'>visibility</i>
                                  View Profile
                                </Button>
                            </Link>
                          </td>
                          <td>{i.name_first}</td>
                          <td>{i.name_last}</td>
                          <td>{i.dob}</td>
                          <td>{i.state.split('-').pop()}</td>
                          <td>{i.county}</td>
                          <td>{i.city}</td>
                          <td>{i.address1}</td>
                          <td>
                            {(i.Orders && i.Orders.length > 0) ||
                              i.hasOneTimeInProgress ? (
                                <Button variant='primary' className='disabled'>
                                  <i className='material-icons'>fingerprint</i>
                                  PreHire Processing
                                </Button>
                              ) : (
                                <Button
                                  variant='primary'
                                  onClick={() =>
                                    this.showModal(
                                      i.subject_id,
                                      i.state,
                                      i.county
                                    )
                                  }
                                >
                                  <i className='material-icons'>fingerprint</i>
                                  Run PreHire Screening
                                </Button>
                              )}
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.subjectsList
                    ? Math.ceil(this.props.subjectsList.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
        {this.state.showModal ? (
          <OneTimeCheckPopUp
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            subjectId={this.state.subjectId}
            state={this.state.state}
            county={this.state.county}
            loadSubjects={this.loadSubjects}
            user={this.state.user}
          />
        ) : null}
      </Container>
    );
  }
}

const mapActionsToProps = {
  getSubjects: getSubjects
};

const mapStateToProps = state => {
  return {
    subjectsList: state.subjectReducer.subjectsList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(SubjectsListPage);
